<template>
  <div ref='businessRadar'
       class="business-radar">
    <div class="search-header">
      <a-form layout="inline"
              :model="searchForm">
        <a-form-item>
          <a-input-search v-model:value="searchForm.titleLike"
                          placeholder="请输入雷达标题"
                          style="width: 26%; min-width: 320px"
                          @keydown.enter="onSearchSubmit"
                          v-debounce="onSearchSubmit" />
        </a-form-item>
      </a-form>
      <router-link :to="{name:'businessRadar_create'}">
        <a-button type="primary"
                  centered
                  style="width: 146px;height: 36px;border-radius:4px">
          <PlusOutlined />新建雷达
        </a-button>
      </router-link>
    </div>

    <a-table :columns="radarColumns"
             :data-source="radar"
             row-key="id"
             :pagination="pagination"
             :scroll="{ x: '100%', y: 'calc(100vh - 322px)' }"
             @change="onPaginationChange"
             :loading="tableLoading">

      <template #radarTitle="{ record }">
        <span class="line-clamp-2">{{record.title}}</span>
      </template>

      <template #example="{ record }">
        <template v-if="record.type === 'link'">
          <div class="msg-link"
               @click="goLinkUrl(record.content, 'link')">
            <div class="link-text">
              <p class="title">{{record.content.linkTitle}}</p>
              <p class="abstract">{{record.content.linkSummary}}</p>
            </div>
            <img class="link-img"
                 :src="record.content.linkCover"
                 alt="">
          </div>
        </template>
        <template v-if="record.type === 'file'">
          <div class="msg-link"
               @click="goLinkUrl(record.content, 'file')">
            <div class="link-text">
              <p class="title">{{record.content.name}}</p>
              <p class="abstract">{{renderSize(record.content.size)}}</p>
            </div>
            <img class="link-img"
                 :src="pdfImgUrl"
                 alt="">
          </div>
        </template>

      </template>

      <template #type="{ text }">
        <span v-if="text === 'link'">链接</span>
        <span v-else>PDF</span>
      </template>

      <template #createdUserWorkWechatThirdAppUserId="{ text }">
        <div v-is="'ww-open-data'"
             class="ww-open-data"
             type="userName"
             :openid="text"></div>
      </template>

      <template #createdTime="{ record }">
        {{$f.datetime(record.createdTime).slice(0, 16)}}
      </template>

      <template #action="{ record }">
        <a-space :size="16">
          <span class="color-primary cursor-pointer"
                @click='openShareDialog(record)'>分享</span>
          <router-link :to="{name: 'businessRadar_detail', query: {id: record.id }}">
            数据
          </router-link>

          <a-dropdown>
            <svg-icon class="icon-more color-primary"
                      type="iconmore"
                      @click.stop />

            <template #overlay>
              <a-menu>
                <a-menu-item>
                  <router-link :to="{name: 'businessRadar_edit', query: {id: record.id }}">
                    修改
                  </router-link>
                </a-menu-item>
                <a-menu-item @click='openDeleteDialog(record.id)'>
                  删除
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </a-space>
      </template>

    </a-table>

    <a-modal title="提示"
             v-model:visible="deleteDialogVisible"
             :confirm-loading="confirmLoading"
             @ok="deleteRadar"
             @cancel='deleteDialogVisible = false'>
      <p>删除后将无法使用该雷达链接，确定删除？</p>
    </a-modal>

    <share-link-modal v-model:visible="shareDialogVisible"
                      title="分享雷达"
                      :item="shareRadar">
      <p class="mt-24 text-12 text-center text-color-999">
        <svg-icon class="mr-8 text-icon"
                  type="iconshuoming" />员工可在企业微信快捷栏「快捷回复」-「商机雷达」菜单找到雷达并发送给客户或客户群。
      </p>
    </share-link-modal>
  </div>
</template>

<script>
import { defineComponent, ref, reactive } from "vue";
import { message, Space } from "ant-design-vue";
import _ from "lodash";

import usePaginationQuery from "@/shared/composables/usePaginationQuery";
import router from "@/router";
import businessRadarApi from "@/service/api/businessRadar";
import { renderSize, copy, downloadImg, getWxAgentConfig } from "@/global";

import SvgIcon from "@/components/SvgIcon";
import ShareLinkModal from "@/components/ShareLinkModal";

export default defineComponent({
  name: "BusinessRadarIndex",
  components: {
    ASpace: Space,
    SvgIcon,
    ShareLinkModal,
  },
  setup() {
    const pdfImgUrl = process.env.VUE_APP_PDF_IMAGE_URL;
    const searchForm = reactive({
      titleLike: "",
    });
    const selectedKeys = ref([]);

    const radarColumns = [
      {
        title: "雷达标题",
        dataIndex: "title",
        slots: { customRender: "radarTitle" },
        width: "250px",
        fixed: "left",
      },

      {
        title: "示例",
        dataIndex: "content",
        slots: { customRender: "example" },
        width: "260px",
      },

      {
        title: "类型",
        dataIndex: "type",
        slots: { customRender: "type" },
        width: "150px",
      },
      {
        title: "点击人数",
        dataIndex: "clickCount",
        width: "150px",
      },
      {
        title: "创建人",
        dataIndex: "createdUserWorkWechatThirdAppUserId",
        width: "150px",
        slots: { customRender: "createdUserWorkWechatThirdAppUserId" },
      },
      {
        title: "创建时间",
        dataIndex: "createdTime",
        slots: { customRender: "createdTime" },
        width: "180px",
      },

      {
        title: "操作",
        slots: { customRender: "action" },
        width: "200px",
        fixed: "right",
      },
    ];

    function goLinkUrl(content, type) {
      if (type === "link") {
        window.open(content.link);
      } else if (type === "file") {
        const link =
          "https://" +
          window.location.host +
          "/customer-h5/" +
          "common/cloudPlayer?no=" +
          content.no +
          "&fileName=" +
          content.name;
        window.open(link);
      }
    }

    const {
      rows,
      pagination,
      pullQueryParams,
      fetchPaginationData,
      onSearchSubmit,
      onPaginationChange,
      tableLoading,
    } = usePaginationQuery(router, searchForm, businessRadarApi.search);

    const deleteDialogVisible = ref(false);
    const confirmLoading = ref(false);
    const deleteRadarId = ref(undefined);
    function openDeleteDialog(id) {
      deleteDialogVisible.value = true;
      deleteRadarId.value = id;
    }
    async function deleteRadar() {
      confirmLoading.value = true;
      await businessRadarApi.delete({ id: deleteRadarId.value });
      confirmLoading.value = false;
      deleteDialogVisible.value = false;
      fetchPaginationData();

      message.success("删除成功");
    }

    const shareDialogVisible = ref(false);
    const shareRadar = reactive({});
    async function openShareDialog(radar) {
      const res = await businessRadarApi.getShareLink({ radarId: radar.id });
      shareRadar.link = res.url;
      _.assign(shareRadar, radar);
      shareDialogVisible.value = true;
    }

    function downloadQrcode(fileName) {
      const img = document.getElementsByClassName("download-img");
      const src = img[0].getAttribute("src");

      downloadImg(src, fileName);
    }

    getWxAgentConfig();

    return {
      renderSize,
      copy,
      pdfImgUrl,

      tableLoading,

      searchForm,
      selectedKeys,

      radarColumns,
      goLinkUrl,

      radar: rows,
      pagination,
      pullQueryParams,
      fetchPaginationData,
      onSearchSubmit,
      onPaginationChange,

      deleteDialogVisible,
      confirmLoading,
      openDeleteDialog,
      deleteRadar,

      shareDialogVisible,
      shareRadar,
      openShareDialog,

      downloadQrcode,
    };
  },
});
</script>
<style lang="less" scoped>
.icon-more {
  font-size: 16px;
}
</style>